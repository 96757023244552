import React from "react";

/* Elements */
import PageDesc from "../molecules/PageDesc";
import ServiceCard from "../molecules/ServiceCard";

/*Images*/
import Picture from "../images/leistungen/Fach.jpg";
import Buecher from "../images/leistungen/Buecher.jpg";
import PetraOeffentlich from "../images/leistungen/Petra-GaLaBau.jpg";
import BildPublikum from "../images/leistungen/PublikumM.jpg";
import FotoUnternehmen from "../images/leistungen/Foto-Unternehmen.jpg";
import Oeffentlich from "../images/leistungen/OeffentlichfVI.JPG";
import { Helmet } from "react-helmet-async";

/* Effects */
import { Fade } from "react-awesome-reveal";
import Inset from "../atoms/Inset";

const Leistungen = () => {
	return (
		<>
			<Helmet prioritizeSeoTags>
				<title>Leistungen - blätterwerk redaktionsbüro</title>
			</Helmet>
			<Inset>
				<PageDesc title="Leistungen" />
			</Inset>
			<div className="m-auto my-20 grid w-11/12 max-w-fit grid-flow-row auto-rows-auto grid-cols-1 grid-rows-br-mobile gap-10 sm:grid-cols-2 sm:grid-rows-3 md:gap-8 lg:grid-cols-3 lg:grid-rows-2">
				<Fade
					cascade={false}
					triggerOnce={true}
					damping={0.5}
					fraction={0.4}
					className="flex max-w-xl flex-col rounded-b-md bg-white shadow-md">
					<ServiceCard
						title="Fachredaktionen"
						bulletPoints={[
							"Kreative Ideen für aktuelle Beiträge",
							"Experteninterviews",
							"Inhaltsstarke Texte und hochwertiges Bildmaterial",
						]}
						images={Picture}
					/>
					<ServiceCard
						title="Publikumsmagazine"
						bulletPoints={[
							"Recherche zu verschiedensten Themen, auch vor Ort",
							"Interviews",
							"Sprachlich an das Medium adaptierte Texte",
							"Hochwertiges Bildmaterial",
						]}
						images={BildPublikum}
					/>
					<ServiceCard
						title="Medienarbeit für Unternehmen"
						bulletPoints={[
							"Öffentlichkeitsarbeit inkl. Umsetzung und Betreuung",
							"Aufbau von Medienkontakten",
							"Firmenbroschüren",
							"Internetseiten",
							"Social Media Konzepte",
							"Newsletter",
							"Firmenportraits",
							"Redaktion für Kundenzeitschriften, Unternehmensmagazine",
						]}
						images={FotoUnternehmen}
					/>
					<ServiceCard
						title="Buchverlage"
						bulletPoints={[
							"Entwicklung und Konzeption marktgerechter Inhalte",
							"Umfassende Recherche",
							"Erstellen der Texte",
							"Auswahl und Beschaffung von Bildmaterial",
						]}
						images={Buecher}
					/>
					<ServiceCard
						title="Öffentlichkeitsarbeit für Verbände und Institutionen"
						bulletPoints={[
							"Redaktionelle Betreuung in Wort und Bild von Messepräsentationen, Seminaren, Workshops, Wettbewerben, Events und Veranstaltungen",
							"Inhalte für Verbandsmitteilungen",
							"Entwicklung von Broschüren",
						]}
						images={Oeffentlich}
					/>
					<ServiceCard
						title=" Medienarbeit für Messen"
						bulletPoints={[
							"Recherche und Verfassen von Pressemitteilungen, so z.B. aktuell",
							"für die Messe GARTEN in Stuttgart",
							"für den Garten- und Landschaftsbau zur Messe INVENTA Garden in Karlsruhe",
							"für die Messe JAGEN UND FISCHEN in Augsburg",
							"Organisation von Pressekonferenzen",
							"Aufbau von Medienkontakten",
						]}
						images={PetraOeffentlich}
					/>
				</Fade>
			</div>
		</>
	);
};

export default Leistungen;
