import React from "react";

/* Import Routing */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

/* Import Molecules */
import Navigation from "./components/molecules/Navigation";
import Footer from "./components/molecules/Footer";

/* Import views */
import Home from "./components/views/Home";
import Referenzen from "./components/views/Referenzen";
import Leistungen from "./components/views/Leistungen";
import Vita from "./components/views/Vita";
import Kontakt from "./components/views/Kontakt";
import Impressum from "./components/views/Impressum";
import DSGVO from "./components/views/DSGVO";
import NotFound from "./components/views/NotFound";
import ScrollToTop from "./components/helper/ScrollToTop";
import Magazine from "./components/views/Magazine";
import Zeitschriften from "./components/views/Zeitschriften";
import { HelmetProvider } from "react-helmet-async";

function App() {
	return (
		<>
			<HelmetProvider>
				<div className="">
					<Router>
						<ScrollToTop />
						<div className="">
							<Navigation />
							<Routes>
								<Route path="/" exact element={<Home />} />
								<Route path="Leistungen" exact element={<Leistungen />} />
								<Route path="Referenzen" exact element={<Referenzen />} />
								<Route path="Referenzen/Magazine" exact element={<Magazine />} />
								<Route path="Referenzen/Zeitschriften" exact element={<Zeitschriften />} />
								<Route path="Vita" exact element={<Vita />} />
								<Route path="Kontakt" exact element={<Kontakt />} />
								<Route path="Impressum" exact element={<Impressum />} />
								<Route path="Datenschutz" exact element={<DSGVO />} />
								<Route path="*" element={<NotFound />} />
							</Routes>
							<Footer />
						</div>
					</Router>
				</div>
			</HelmetProvider>
		</>
	);
}

export default App;
