import React, { useEffect, useState } from "react";
import axios from "axios";

const ShowWithMedia = ({ mediaId, pdfUrl, title, publisher, date }) => {
	const [year, setYear] = useState("");
	const [month, setMonth] = useState("");
	const [ImageData, setImageData] = useState({ media_details: { sizes: { full: { source_url: "" } } } });

	useEffect(() => {
		setYear(date.substr(3, 4));
		setMonth(date.substr(0, 2));

		async function fetchMediaData() {
			const reqOne = await axios(`https://api.blaetterwerk-redaktionsbuero.de/wp-json/wp/v2/media/${mediaId}`);
			setImageData(reqOne.data);
		}
		fetchMediaData();
	}, [date, mediaId]);
	return (
		<div className="mx-5 my-5 w-80 md:w-96">
			<div className="overflow-hidden rounded bg-white shadow-lg">
				<a href={pdfUrl} target="_blank" rel="noreferrer">
					<div className="relative">
						<img
							className="h-96 w-full object-cover object-top"
							src={ImageData.media_details.sizes.full.source_url}
							alt={title}
						/>
						<div className="absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25 transition duration-300 hover:bg-transparent"></div>
					</div>
				</a>
				<div className="px-6 py-4">
					<a
						href={pdfUrl}
						className="inline-block text-lg font-semibold text-gray-900 transition duration-500 ease-in-out hover:text-dark-green-200">
						{title.replace("&#8211;", "-")}
					</a>
					<p className="text-sm text-gray-500">{publisher}</p>
					<p className="text-sm text-gray-500">{`AUSGABE ${month}/${year}`}</p>
				</div>
			</div>
		</div>
	);
};

export default ShowWithMedia;
