import React from "react";

import PageDesc from "../molecules/PageDesc";

import Obfuscate from "react-obfuscate";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import Inset from "../atoms/Inset";
import { Helmet } from "react-helmet-async";

const Kontakt = () => {
	const position = [48.178285, 9.54213];

	return (
		<>
			<Helmet prioritizeSeoTags>
				<title>Kontakt - blätterwerk redaktionsbüro</title>
			</Helmet>
			<Inset>
				<PageDesc title="Kontakt" />
			</Inset>
			<MapContainer height={300} center={position} zoom={12} scrollWheelZoom={false}>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<Marker position={position}>
					<Popup>
						blätterwerk redaktionsbüro <br />
						Petra Reidel <br />
						Dorfstraße 42 <br />
						88527 Unlingen
					</Popup>
				</Marker>
			</MapContainer>
			<div className="m-auto my-20 flex w-11/12 flex-col">
				<div className="mx-auto mb-2 flex flex-row items-center justify-center">
					<span className="mr-2 text-xl font-light text-dark-green-300">blätterwerk</span>
					<span className="text-xl font-light text-light-green">redaktionsbüro</span>
				</div>
				<h3 className="mb-2 text-center text-xl font-semibold text-gray-900">Dipl.-Ing. agr. Petra Reidel</h3>
				<p className="text-md mb-2 text-center font-normal text-gray-900 sm:text-xl">
					Dorfstraße 42 <br></br>88527 Unlingen
				</p>
				<h3 className="mb-2 text-center text-xl font-semibold text-gray-900">Kontakt</h3>
				<p className="text-md mb-2 text-center font-normal text-gray-900 sm:text-xl">
					Mobil:{" "}
					<a
						href="tel:0175/2711433"
						className="text-md border-b-2 border-light-green font-normal text-gray-600 sm:text-xl">
						0175 2711433
					</a>
				</p>
				<p className="text-md mb-2 text-center font-normal text-gray-900 sm:text-xl">
					Tel:{" "}
					<a
						href="tel: 073711069707"
						className="text-md border-b-2 border-light-green font-normal text-gray-600 sm:text-xl">
						07371 1069707
					</a>
				</p>
				<p className="text-md mb-2 text-center font-normal text-gray-900 sm:text-xl">
					E-Mail:{""} <br className="sm:hidden"></br>{" "}
					<Obfuscate
						obfuscate={true}
						email="info@blaetterwerk-redaktionsbuero.de"
						className="text-md border-b-2 border-light-green font-normal text-gray-600 sm:text-xl"
					/>{" "}
				</p>
			</div>
		</>
	);
};

export default Kontakt;
