import React from "react";

const PageDesc = ({ title, description }) => {
	return (
		<>
			<div className="my-10">
				<h2 className="w-min border-b-4 border-light-green text-2xl font-semibold text-gray-900">{title}</h2>
				<h3 className="text-lg font-normal text-gray-700">{description}</h3>
			</div>
		</>
	);
};

export default PageDesc;
