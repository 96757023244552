import React from "react";

import PageDesc from "../molecules/PageDesc";

import { Fade } from "react-awesome-reveal";

import PetraGroß from "../images/vita/Petra-Fritzi_groß.jpg";
import Timeline from "../molecules/TimeLine";
import Inset from "../atoms/Inset";
import { Helmet } from "react-helmet-async";

const Vita = () => {
	return (
		<>
			<Helmet prioritizeSeoTags>
				<title>Vita - blätterwerk redaktionsbüro</title>
			</Helmet>
			<Inset>
				<PageDesc title="Vita" />
			</Inset>
			<div className="bg-dark-green-200">
				<div className="relative top-8 m-auto mb-24 flex w-11/12 max-w-[800px] flex-row justify-center md:top-24">
					<div className="mr-4 flex flex-col justify-center md:mr-5 lg:mr-6">
						<h3 className="text-right font-Prata text-3xl font-normal text-white md:text-5xl lg:text-7xl">
							Petra
						</h3>
						<h3 className="text-right font-Prata text-3xl font-normal text-white md:text-5xl lg:text-7xl">
							Reidel
						</h3>
						<p className="hidden text-right text-lg text-white">freischaffende Journalistin</p>
					</div>
					<div className="">
						<img src={PetraGroß} alt="Petra und Fritzi" className="rounded-md md:rounded-lg" />
					</div>
				</div>
			</div>

			{/* Timeline */}
			<Fade triggerOnce={true}>
				<Timeline />
			</Fade>
		</>
	);
};

export default Vita;
