import axios from "axios";
import React, { useState, useEffect } from "react";
import BackButton from "../atoms/BackButton";
import Inset from "../atoms/Inset";

import ShowWithMedia from "../atoms/ShowWithMedia";

import PageDesc from "../molecules/PageDesc";

import { Helmet } from "react-helmet-async";

const Magazine = () => {
	const [magazines, setMagazines] = useState([]);

	useEffect(() => {
		async function fetchData() {
			const res = await axios("https://api.blaetterwerk-redaktionsbuero.de/wp-json/wp/v2/magazine");
			setMagazines(res.data);
		}
		fetchData();
	}, []);
	return (
		<>
			<Helmet prioritizeSeoTags>
				<title>Publikumsmagazine - blätterwerk redaktionsbüro</title>
			</Helmet>
			<Inset>
				<PageDesc title="Publikumsmagazine" />
				<BackButton text="zurück" />
			</Inset>

			<div className="m-auto flex flex-col items-center justify-evenly md:w-11/12 md:flex-row md:flex-wrap md:items-start">
				{magazines.map((magazin) => {
					return (
						<ShowWithMedia
							key={magazin.id}
							mediaId={magazin.featured_media}
							pdfUrl={magazin.acf.link_to_pdf.url}
							title={magazin.title.rendered}
							publisher={magazin.acf.publisher}
							date={magazin.acf.date}
						/>
					);
				})}
			</div>
		</>
	);
};

export default Magazine;
