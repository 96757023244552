import React from "react";
import Inset from "../atoms/Inset";
import PageDesc from "../molecules/PageDesc";
import { Helmet } from "react-helmet-async";

const DSGVO = () => {
	return (
		<>
			<Helmet prioritizeSeoTags>
				<title>Datenschutzerklärung - blätterwerk redaktionsbüro</title>
			</Helmet>
			<Inset>
				<PageDesc title="Datenschutzerklärung" />
			</Inset>
			<div className="m-auto my-10 w-11/12">
				<h1 className="hidden text-3xl font-semibold">Datenschutzerklärung</h1>
				<h2 className="text-xl font-semibold">1. Datenschutz auf einen Blick</h2>
				<h3 className="text-lg font-semibold">"Allgemeine Hinweise</h3>
				Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
				passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
				persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie
				unserer unter diesem Text aufgeführten Datenschutzerklärung.
				<h3 className="text-lg font-semibold">"Datenerfassung auf unserer Website</h3>
				<strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong>
				Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können
				Sie dem Impressum dieser Website entnehmen.
				<strong>Wie erfassen wir Ihre Daten?</strong>
				Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um
				Daten handeln, die Sie in ein Kontaktformular eingeben. Andere Daten werden automatisch beim Besuch der
				Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser,
				Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald
				Sie unsere Website betreten.
				<strong>Wofür nutzen wir Ihre Daten?</strong>
				Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
				Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
				<strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
				Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
				gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung,
				Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz
				können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht
				Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
				<h3 className="text-lg font-semibold">"Analyse-Tools und Tools von Drittanbietern</h3>
				Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor
				allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in
				der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser
				Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte
				Informationen dazu finden Sie in der folgenden Datenschutzerklärung. Sie können dieser Analyse
				widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in dieser Datenschutzerklärung
				informieren.
				<h2 className="text-xl font-semibold">2. Allgemeine Hinweise und Pflichtinformationen</h2>
				<h3 className="text-lg font-semibold">"Datenschutz</h3>
				Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
				personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie
				dieser Datenschutzerklärung. Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten
				erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die
				vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
				erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen darauf hin, dass die Datenübertragung
				im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser
				Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
				<h3 className="text-lg font-semibold">"Hinweis zur verantwortlichen Stelle</h3>
				<p>
					Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist: Petra Reidel
					Blätterwerk Redaktionsbüro Dorfstraße 42 88527 Unlingen Deutschland Telefon: 01752711433 E-Mail:
					info@blaetterwerk-redaktionsbuero.de
				</p>
				Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen
				über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o.
				Ä.) entscheidet.
				<h3 className="text-lg font-semibold">"Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
				Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
				bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
				uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
				<h3 className="text-lg font-semibold">"Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
				Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen
				Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
				Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der
				Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden:{" "}
				<a
					href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
					target="_blank"
					rel="noreferrer">
					https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
				</a>
				.<h3 className="text-lg font-semibold">"Auskunft, Sperrung, Löschung</h3>
				Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
				Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
				Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie
				zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum
				angegebenen Adresse an uns wenden.
				<h3 className="text-lg font-semibold">"Widerspruch gegen Werbe-Mails</h3>
				Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht
				ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber
				der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
				Werbeinformationen, etwa durch Spam-E-Mails, vor.
				<h2 className="text-xl font-semibold">3. Datenerfassung auf unserer Website</h2>
				<h3 className="text-lg font-semibold">"Cookies</h3>
				Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen
				Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver
				und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die
				Ihr Browser speichert. Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”.
				Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät
				gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch
				wiederzuerkennen. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies
				informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle
				oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser
				aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
				Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung
				bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf
				Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes
				Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung
				seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert
				werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.
				<h3 className="text-lg font-semibold">"Server-Log-Dateien</h3>
				Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
				Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
				<ul>
					<li>Browsertyp und Browserversion</li>
					<li>verwendetes Betriebssystem</li>
					<li>Referrer URL</li>
					<li>Hostname des zugreifenden Rechners</li>
					<li>Uhrzeit der Serveranfrage</li>
					<li>IP-Adresse</li>
				</ul>
				Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Grundlage für die
				Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines
				Vertrags oder vorvertraglicher Maßnahmen gestattet.
				<h2 className="text-xl font-semibold">4. Plugins und Tools</h2>
				<h3 className="text-lg font-semibold">"Google Web Fonts</h3>
				Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google
				bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
				Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Zu diesem Zweck muss der von Ihnen
				verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
				darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts
				erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies
				stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Wenn Ihr Browser Web
				Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt. Weitere Informationen zu
				Google Web Fonts finden Sie unter{" "}
				<a href="https://developers.google.com/fonts/faq" target="_blank" rel="noreferrer">
					developers.google.com/fonts/faq
				</a>{" "}
				und in der Datenschutzerklärung von Google:{" "}
				<a href="https://www.google.com/policies/privacy/" target="_blank" rel="noreferrer">
					https://www.google.com/policies/privacy/
				</a>
				.<h3 className="text-lg font-semibold">"Google Maps</h3>
				Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc., 1600
				Amphitheatre Parkway, Mountain View, CA 94043, USA. Zur Nutzung der Funktionen von Google Maps ist es
				notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von
				Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf
				diese Datenübertragung. Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung
				unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen
				Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Mehr
				Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google:{" "}
				<a href="https://www.google.de/intl/de/policies/privacy/" target="_blank" rel="noreferrer">
					https://www.google.de/intl/de/policies/privacy/
				</a>
				.
			</div>
		</>
	);
};

export default DSGVO;
