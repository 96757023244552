import React from "react";

import { Link } from "react-router-dom";

import MamaPortrait from "../images/home/Mama-Gut.jpg";
import Logo from "../images/logo_blaetterwerk_intro_edit.png";

const VitaCard = () => {
	return (
		<>
			<div className="m-auto mb-14 flex w-11/12 flex-col  md:w-3/5">
				<div className="my-4 mx-auto flex flex-col items-center justify-center md:mb-24">
					<img src={Logo} alt="Logo" className="m-auto h-40 w-auto md:h-52" />
				</div>
				<div className="mt-10 grid w-full grid-cols-2 grid-rows-br-mobile gap-3 md:gap-8">
					<div className=" flex items-center justify-center">
						<div className="">
							<img
								src={MamaPortrait}
								alt="Petra Profil"
								className="h-full w-full max-w-portImg rounded-full border-2 border-dark-green-200 border-opacity-70 "
							/>
						</div>
					</div>
					<div className="flex items-center">
						<div className="flex flex-row items-center justify-center">
							<span className="text-md md:text-2xl">
								Dahinter stecke ich, <br />
								<span className=" text-xl md:text-3xl">Petra Reidel</span>
							</span>
						</div>
					</div>
					<div className="col-span-2 rounded-md bg-dark-green-200 p-10 shadow-md">
						<p className="leading-8 text-white">
							Mit meiner Erfahrung, meinem fundierten Fachwissen, meinem Interesse an Neuem sowie an
							verschiedensten Themenbereichen und meiner Freude am Führen von Interviews. Erfolgreiche
							Recherche, professionelle Texte und Bilder gehören ebenso zu mir, wie klare und effiziente
							Strukturen. Zudem profitieren meine Kunden von meinem sehr guten Branchennetzwerk.
						</p>
						<div className="mt-2">
							<span className="text-white">Mehr zu meiner Arbeit:</span>{" "}
							<Link to="/Leistungen" className="border-b-2 border-light-green px-3 text-xl text-white">
								Leistungen
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default VitaCard;
