import React from "react";
import Inset from "../atoms/Inset";
import PageDesc from "../molecules/PageDesc";

import { Helmet } from "react-helmet-async";

const NotFound = () => {
	return (
		<>
			<Helmet prioritizeSeoTags>
				<title>404 Nicht gefunden - blätterwerk redaktionsbüro</title>
			</Helmet>
			<Inset>
				<PageDesc
					title="Diese Seite konnte nicht gefunden werden!"
					description="Bitte kehren Sie zur Home Seite zurück"
				/>
			</Inset>
		</>
	);
};

export default NotFound;
