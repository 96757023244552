import React from "react";

import AugalaLogo from "../images/refBanner/Augala_logo.svg";
import CorthumLogo from "../images/refBanner/Corthum_logo.webp";
import GalabauLogo from "../images/refBanner/Galabau_logo.png";
import TASPOLogo from "../images/refBanner/TASPO_logo.png";
import DEGALogo from "../images/refBanner/DEGA_logo.png";
import PirschLogo from "../images/refBanner/PIRSCH_Logo.jpg";
import LJVLogo from "../images/refBanner/LJV_logo.png";
import RWTLogo from "../images/refBanner/RWT_logo.svg";
import BuGLogo from "../images/refBanner/BuG_ogo.png";
import MesseALogo from "../images/refBanner/MesseA_logo.png";

const RefBanner = () => {
	const clients = [
		AugalaLogo,
		CorthumLogo,
		GalabauLogo,
		TASPOLogo,
		DEGALogo,
		PirschLogo,
		LJVLogo,
		RWTLogo,
		BuGLogo,
		MesseALogo,
	];
	return (
		<>
			<div className="flex flex-col">
				<span className="mx-auto mb-12 w-full text-center text-2xl font-normal">Meine Kunden</span>
				<div className="relative h-36 w-full overflow-hidden md:h-44">
					<div className="flex w-[8000px] animate-scroll-40 flex-row">
						{clients.map((client, key) => {
							return (
								<div key={key} className="mr-10 md:mr-24">
									<img src={client} alt="" className="h-16 w-auto grayscale filter md:h-28" />
								</div>
							);
						})}
						{clients.map((client, key) => {
							return (
								<div key={key} className="mr-10 md:mr-24">
									<img src={client} alt="" className="h-16 w-auto grayscale filter md:h-28" />
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default RefBanner;
