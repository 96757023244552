import { useNavigate } from "react-router";

const BackButton = ({ text }) => {
	const navigate = useNavigate();
	return (
		<>
			<button
				onClick={() => {
					navigate(-1);
				}}
				className="mb-2 border-b-2 border-black text-sm">
				{text}
			</button>
		</>
	);
};

export default BackButton;
