import React from "react";

const ServiceCard = ({ title, bulletPoints, images }) => {
	return (
		<>
			<img src={images} alt={title} className="min-h-[195px]" />
			<div className="flex flex-col px-4">
				<h3 className="mt-8 mb-4 text-xl font-semibold tracking-wide text-gray-900">{title}</h3>
				<ul className="mb-4 list-inside list-disc">
					{bulletPoints.map((content, key) => {
						return (
							<li className="ml-4 mt-2 list-outside text-light-green" key={key}>
								<span className="text-gray-900">{content}</span>
							</li>
						);
					})}
				</ul>
			</div>
		</>
	);
};

export default ServiceCard;
