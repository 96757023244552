import React from "react";

import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
	const socials = [
		{ url: "https://instagram.com/blaetterwerkredaktionsbuero", icon: faInstagram },
		{ url: "https://www.facebook.com/PressearbeitvomFeinsten/", icon: faFacebookSquare },
	];

	return (
		<>
			<div className="bg-dark-green-200 py-10 flex flex-col md:flex-row items-center justify-around order-1">
				<div className="flex flex-row justify-center md:w-1/3 w-full my-2">
					{socials.map((social, key) => {
						return socials.length - 1 === key ? (
							<a key={key} href={social.url} target="_blank" rel="noreferrer">
								<FontAwesomeIcon
									icon={social.icon}
									size="3x"
									className="hover:text-light-green text-white transition-colors"
								/>
							</a>
						) : (
							<a key={key} href={social.url} target="_blank" rel="noreferrer" className="mr-10">
								<FontAwesomeIcon
									icon={social.icon}
									size="3x"
									className="hover:text-light-green text-white transition-colors"
								/>
							</a>
						);
					})}
				</div>
				<div className="md:w-1/3 w-full my-2 flex justify-center order-3 md:order-2">
					<a
						href="https://www.jonasreidel.de"
						target="_blank"
						rel="noreferrer"
						className="text-md font-normal text-white border-b-2 border-light-green">
						Made by Jonas Reidel
					</a>
				</div>
				<div className="flex flex-col md:w-1/3 w-full justify-between my-2 order-2">
					<a
						href="https://kundencenter.blaetterwerk-redaktionsbuero.de/"
						target="_blank"
						rel="noreferrer"
						className="text-white text-center hover:text-light-green px-3 py-2 rounded-md hover:bg-dark-green-200 w-full md:w-48 mx-auto">
						Kundencenter
					</a>
					<NavLink
						to="/Datenschutz"
						className={
							"text-white text-center hover:text-light-green px-3 py-2 rounded-md hover:bg-dark-green-200 w-full md:w-48 mx-auto "
						}>
						Datenschutzerklärung
					</NavLink>
					<NavLink
						to="/Impressum"
						className={
							"text-white text-center hover:text-light-green px-3 py-2 rounded-md hover:bg-dark-green-200 w-full md:w-48 mx-auto "
						}>
						Impressum
					</NavLink>
				</div>
			</div>
		</>
	);
};

export default Footer;
