import React from "react";
import Obfuscate from "react-obfuscate";
import Inset from "../atoms/Inset";
import PageDesc from "../molecules/PageDesc";
import { Helmet } from "react-helmet-async";

const Impressum = () => {
	return (
		<>
			<Helmet prioritizeSeoTags>
				<title>Impressum - blätterwerk redaktionsbüro</title>
			</Helmet>
			<Inset>
				<PageDesc title="Impressum" />
			</Inset>
			<div className="m-auto my-10 w-11/12">
				<h3 className="mt-4 mb-2 text-xl font-semibold text-gray-900 ">
					Verantwortlich für den Inhalt dieser Webseite
				</h3>
				<p className="text-md font-normal text-gray-600">Petra Reidel</p>
				<h3 className="mt-4 mb-2 text-xl font-semibold text-gray-900 ">Adresse</h3>
				<p className="text-md font-normal text-gray-600">
					Dorfstraße 42 <br></br>88527 Unlingen
				</p>
				<h3 className="mt-4 mb-2 text-xl font-semibold text-gray-900 ">Kontakt</h3>
				<p className="text-md font-normal text-gray-600">
					Mobil:{" "}
					<a
						href="tel:0175/2711433"
						className="text-md border-b-2 border-light-green font-normal text-gray-600">
						0175 2711433
					</a>
				</p>
				<p className="text-md font-normal text-gray-600">
					Tel:{" "}
					<a
						href="tel: 073711069707"
						className="text-md border-b-2 border-light-green font-normal text-gray-600">
						07371 1069707
					</a>
				</p>
				<p className="text-md font-normal text-gray-600">
					E-Mail:{""} <br className="sm:hidden"></br>{" "}
					<Obfuscate
						obfuscate={true}
						email="info@blaetterwerk-redaktionsbuero.de"
						className="text-md border-b-2 border-light-green font-normal text-gray-600"
					/>{" "}
				</p>
				<h3 className="mt-4 mb-2 text-xl font-semibold text-gray-900 ">USt-ID</h3>
				<p className="text-md font-normal text-gray-600">DE215058443</p>
				<h3 className="mt-4 mb-2 text-xl font-semibold text-gray-900 ">Quelltext, Webfassung</h3>
				<p className="text-md font-normal text-gray-600">
					<a
						href="https://www.jonasreidel.de"
						target="_blank"
						rel="noreferrer"
						className="text-md border-b-2 border-light-green font-normal text-gray-600">
						Jonas Reidel
					</a>
				</p>
				<h3 className="mt-4 mb-2 text-xl font-semibold text-gray-900 ">Bildquellen</h3>
				<p className="text-md font-normal text-gray-600">Petra Reidel, Sven Falk, Klaus Schmadalla</p>
				<h3 className="mt-4 mb-2 text-xl font-semibold text-gray-900 ">Haftungsausschluss</h3>
				<p className="text-md font-normal text-gray-600">
					Wir distanzieren uns ausdrücklich von allen Inhalten aller Seiten, auf die wir verlinken und machen
					uns diese Inhalte nicht zu eigen. Diese Erklärung gilt für alle auf dieser Webseite angebrachten
					Links. In keinem Fall sind wir verantwortlich für die Inhalte von Internetseiten, die auf uns
					verweisen. Das blätterwerk redaktionsbüro kann daher für entstandene Schäden, entgangene Vorteile
					oder sonstige mittelbare oder unmittelbare Folgen, welche aus der Nutzung der hier angebotenen
					Informationen entstehen, nicht haftbar gemacht werden.
				</p>
			</div>
		</>
	);
};

export default Impressum;
