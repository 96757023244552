import React from "react";
import { Link } from "react-router-dom";

const ModShowBanner = ({ title, img, bg, imgDimensions }) => {
	return (
		<>
			<div className={"my-10 py-10 " + bg}>
				<p className="text-center text-2xl font-normal">{title}</p>
				<div className="my-10 flex flex-col items-center justify-around md:flex-row">
					{img.map((image, key) => {
						return image.link !== false ? (
							<Link to={image.link} key={key}>
								<div className="my-4 flex max-w-xs flex-col items-center">
									<img
										src={image.src}
										alt={image.alt}
										width={imgDimensions?.width}
										height={imgDimensions?.height}
										className=" bg-slate-200"
									/>
									<h3 className="mt-3 border-b-2 border-light-green text-center text-lg">
										{image.desc}
									</h3>
								</div>
							</Link>
						) : (
							<div className="flex max-w-xs flex-col items-center" key={key}>
								<img
									src={image.src}
									alt={image.alt}
									width={imgDimensions?.width}
									height={imgDimensions?.height}
								/>
								<h3 className="mt-3 text-center text-lg">{image.desc}</h3>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default ModShowBanner;
