import React from "react";

const Timeline = () => {
	return (
		<>
			<div className="container mx-auto h-full w-full">
				<div className="wrap relative h-full overflow-hidden p-10">
					<div className="border-2-2 br-left-50 absolute hidden h-full border border-gray-700 border-opacity-20 md:-top-32 md:block lg:-top-28"></div>
					<div className="right-timeline mb-8 flex w-full items-center justify-between">
						<div className="order-1 hidden w-5/12 md:block"></div>
						<div className="z-20 order-1 hidden h-8 w-8 items-center rounded-full bg-light-green shadow-xl md:flex"></div>
						<div className="relative order-1 h-[300px] w-full rounded-lg bg-dog-small bg-cover bg-center bg-no-repeat shadow-xl md:w-5/12">
							<div className=" relative top-0 h-full rounded-lg bg-gradient-to-t from-dark-green-300 to-transparent px-6 py-4">
								<div className="flex h-full w-full flex-col justify-end">
									<h3 className="mb-3 text-xl font-bold text-white">2018</h3>
									<p className="text-sm leading-snug tracking-wide text-white text-opacity-100">
										Neue Mitarbeiterin bei der redaktionellen Spurensuche: Bahiya, Deutsche Bracke
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="left-timeline mb-8 flex w-full flex-row-reverse items-center justify-between">
						<div className="order-1 hidden w-5/12 md:block"></div>
						<div className="z-20 order-1 hidden h-8 w-8 items-center rounded-full bg-light-green shadow-xl md:flex"></div>
						<div className="relative order-1 h-[300px] w-full rounded-lg bg-dog-big bg-cover bg-center bg-no-repeat shadow-xl md:w-5/12">
							<div className=" relative top-0 h-full rounded-lg bg-gradient-to-t from-dark-green-300 to-transparent px-6 py-4">
								<div className="flex h-full w-full flex-col justify-end">
									<h3 className="mb-3 text-xl font-bold text-white">2014</h3>
									<p className="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100">
										Immer flott dabei auf der redaktionellen Spurensuche: Fritzi, BGS
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="right-timeline mb-8 flex w-full items-center justify-between">
						<div className="order-1 hidden w-5/12 md:block"></div>
						<div className="z-20 order-1 hidden h-8 w-8 items-center rounded-full bg-light-green shadow-xl md:flex"></div>
						<div className="order-1 w-full rounded-lg bg-dark-green-200 px-6 py-4 shadow-xl md:w-5/12">
							<h3 className="mb-3 text-xl font-bold text-white">2000</h3>
							<p className="text-sm leading-snug tracking-wide text-white text-opacity-100">
								Gründung <br></br> <strong>blätterwerk redaktionsbüro</strong> <br></br> Die Geburt
								meiner Kinder gab mir den Impuls zur Selbständigkeit. Mit hoher zeitlicher Flexibilität
								kann ich seitdem mein Talent zum Schreiben sehr gut in bestehende Strukturen einbringen.
								Dieses erfolgreiche Modell kommt bis heute allen Beteiligten und Kunden zugute.
							</p>
						</div>
					</div>

					<div className="left-timeline mb-8 flex w-full flex-row-reverse items-center justify-between">
						<div className="order-1 hidden w-5/12 md:block"></div>
						<div className="z-20 order-1 hidden h-8 w-8 items-center rounded-full bg-light-green shadow-xl md:flex"></div>
						<div className="order-1 w-full rounded-lg bg-dark-green-200 px-6 py-4 shadow-xl md:w-5/12">
							<h3 className="mb-3 text-xl font-bold text-white">1997 bis 1999</h3>
							<p className="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100">
								Referentin für Öffentlichkeitsarbeit und Nachwuchswerbung im Verband Garten-,
								Landschafts- und Sportplatzbau Baden-Württemberg e.V.
							</p>
						</div>
					</div>

					<div className="right-timeline mb-8 flex w-full items-center justify-between">
						<div className="order-1 hidden w-5/12 md:block"></div>
						<div className="z-20 order-1 hidden h-8 w-8 items-center rounded-full bg-light-green shadow-xl md:flex"></div>
						<div className="order-1 w-full rounded-lg bg-dark-green-200 px-6 py-4 shadow-xl md:w-5/12">
							<h3 className="mb-3 text-xl font-bold text-white">1991 bis 1996</h3>
							<p className="text-sm leading-snug tracking-wide text-white text-opacity-100">
								Positionen in Unternehmen der grünen Branche
							</p>
						</div>
					</div>

					<div className="left-timeline mb-8 flex w-full flex-row-reverse items-center justify-between">
						<div className="order-1 hidden w-5/12 md:block"></div>
						<div className="z-20 order-1 hidden h-8 w-8 items-center rounded-full bg-light-green shadow-xl md:flex"></div>
						<div className="order-1 w-full rounded-lg bg-dark-green-200 px-6 py-4 shadow-xl md:w-5/12">
							<h3 className="mb-3 text-xl font-bold text-white">1991</h3>
							<p className="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100">
								Diplom-Agraringenieurin Fachrichtung Gartenbau, Technische Universität München
								Weihenstephan
							</p>
						</div>
					</div>

					<div className="right-timeline mb-8 flex w-full items-center justify-between">
						<div className="order-1 hidden w-5/12 md:block"></div>
						<div className="z-20 order-1 hidden h-8 w-8 items-center rounded-full bg-light-green shadow-xl md:flex"></div>
						<div className="order-1 w-full rounded-lg bg-dark-green-200 px-6 py-4 shadow-xl md:w-5/12">
							<h3 className="mb-3 text-xl font-bold text-white">1986</h3>
							<p className="text-sm leading-snug tracking-wide text-white text-opacity-100">
								Gärtnerin, Fachrichtung Zierpflanzen und Stauden, Kempten
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Timeline;
