import axios from "axios";
import React, { useState, useEffect } from "react";
import BackButton from "../atoms/BackButton";
import Inset from "../atoms/Inset";
import { Helmet } from "react-helmet-async";

import ShowWithMedia from "../atoms/ShowWithMedia";

import PageDesc from "../molecules/PageDesc";

const Zeitschriften = () => {
	const [Journals, setJournals] = useState([]);

	useEffect(() => {
		async function fetchData() {
			const res = await axios("https://api.blaetterwerk-redaktionsbuero.de/wp-json/wp/v2/journals");
			setJournals(res.data);
		}
		fetchData();
	}, []);

	return (
		<>
			<Helmet prioritizeSeoTags>
				<title>Fachzeitschriften - blätterwerk redaktionsbüro</title>
			</Helmet>
			<Inset>
				<PageDesc title="Fachzeitschriften" />
				<BackButton text="zurück" />
			</Inset>
			<div className="m-auto flex flex-col items-center justify-evenly md:w-11/12 md:flex-row md:flex-wrap md:items-start">
				{Journals.map((journal) => {
					return (
						<ShowWithMedia
							key={journal.id}
							mediaId={journal.featured_media}
							pdfUrl={journal.acf.link_to_pdf.url}
							title={journal.title.rendered}
							publisher={journal.acf.publisher}
							date={journal.acf.date}
						/>
					);
				})}
			</div>
		</>
	);
};

export default Zeitschriften;
