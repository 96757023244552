import React, { useEffect, useState } from "react";
import axios from "axios";

import ModShowBanner from "../molecules/ModShowBanner";
import PageDesc from "../molecules/PageDesc";
import RefBanner from "../molecules/RefBanner";
import { Helmet } from "react-helmet-async";

/* Images */

import Buch1 from "../images/referenzen/Buch_Cover_dachgaerten.jpg";
import Buch2 from "../images/referenzen/Buch_Cover_gartenkonzepte.jpg";
import Buch3 from "../images/referenzen/Buch_Cover_natursteine.jpg";
import Inset from "../atoms/Inset";
import { Fade } from "react-awesome-reveal";

const Referenzen = () => {
	const [PubImg, setPubImg] = useState({});
	const [JourImg, setJourImg] = useState({});

	useEffect(() => {
		async function fetchPubData() {
			const reqOne = await axios("https://api.blaetterwerk-redaktionsbuero.de/wp-json/wp/v2/magazine");
			const reqTwo = await axios(
				`https://api.blaetterwerk-redaktionsbuero.de/wp-json/wp/v2/media/${reqOne.data[0].featured_media}`
			);
			setPubImg(reqTwo.data.media_details.sizes.large.source_url);
		}
		fetchPubData();

		async function fetchJourData() {
			const reqOne = await axios("https://api.blaetterwerk-redaktionsbuero.de/wp-json/wp/v2/journals");
			const reqTwo = await axios(
				`https://api.blaetterwerk-redaktionsbuero.de/wp-json/wp/v2/media/${reqOne.data[0].featured_media}`
			);
			setJourImg(reqTwo.data.media_details.sizes.large.source_url);
		}
		fetchJourData();
	}, []);

	return (
		<>
			<Helmet prioritizeSeoTags>
				<title>Referenzen - blätterwerk redaktionsbüro</title>
			</Helmet>
			<Inset>
				<PageDesc title="Referenzen" />
			</Inset>

			<Fade triggerOnce={true}>
				<ModShowBanner
					title="Bücher"
					img={[
						{ src: Buch1, alt: "Dachgärten", link: false, desc: "Moderne Dachgärten" },
						{ src: Buch2, alt: "Gartenkonzepte", link: false, desc: "Moderne Gartenkonzepte" },
						{ src: Buch3, alt: "Naturstein", link: false, desc: "Naturstein im Garten" },
					]}
					imgDimensions={{ width: 250, height: 195 }}
				/>
			</Fade>
			<Fade triggerOnce={true}>
				<ModShowBanner
					title="Magazine und Zeitschriften"
					bg="bg-gray-50"
					img={[
						{
							src: PubImg,
							alt: "Publikumsmagazine",
							desc: "Publikumsmagazine >",
							link: "/Referenzen/Magazine",
						},
						{
							src: JourImg,
							alt: "Fachzeitschriften",
							desc: "Fachzeitschriften >",
							link: "/Referenzen/Zeitschriften",
						},
					]}
					imgDimensions={{ width: 320, height: 459 }}
				/>
			</Fade>
			<Fade triggerOnce={true}>
				<RefBanner />
			</Fade>
		</>
	);
};

export default Referenzen;
