import React, { useRef } from "react";

import SimpleImageSlider from "react-simple-image-slider";

import { Fade } from "react-awesome-reveal";

/* Images */
import Slide1 from "../images/first.jpeg";
import Slide2 from "../images/second.jpeg";
import Slide3 from "../images/home/Blume-nah.JPG";
import Slide4 from "../images/home/Blume-Rot.jpg";
import Slide5 from "../images/home/Garten-Weiß.jpg";
import Slide6 from "../images/home/Garten-hell-e.jpg";
//import RadHell from "../images/SVG/rad-hell.svg";
/* Elements */
import VitaCard from "../molecules/VitaCard";
import RefBanner from "../molecules/RefBanner";
import { Helmet } from "react-helmet-async";

const Home = () => {
	const toScroll = useRef();

	const slideImages = [
		{ url: Slide1 },
		{ url: Slide2 },
		{ url: Slide6 },
		{ url: Slide4 },
		{ url: Slide3 },
		{ url: Slide5 },
	];
	const ImageSliderStyle = {
		width: "100%",
		height: window.innerHeight - 96 + "px",
	};

	function scrollOnClick() {
		toScroll.current.scrollIntoView({ behavior: "smooth" });
	}

	return (
		<>
			<Helmet prioritizeSeoTags>
				<title>Home - blätterwerk redaktionsbüro</title>
				<meta
					name="description"
					content="blätterwerk redaktionsbüro steht für die Leidenschaft, schwierige Zusammenhänge auf den Punkt zu bringen, lesenswert zu beschreiben und möglichst viele Menschen an diesem Wissen teilhaben zu lassen."
				/>
			</Helmet>
			<div className="relative w-screen overflow-x-hidden">
				{/* 
				<div className="pointer-events-none absolute -right-[15rem] -top-[15rem] z-10 hidden h-[35rem] w-[35rem] overflow-hidden md:block lg:h-[50rem] lg:w-[50rem]">
					<img src={RadHell} alt="Rad hellgrün" className="h-full w-full opacity-20" />
				</div>*/}
				<SimpleImageSlider
					width={ImageSliderStyle.width}
					height={ImageSliderStyle.height}
					images={slideImages}
					showBullets={false}
					showNavs={true}
					navMargin={15}
					navSize={35}
					loop={true}
					autoPlay={true}
					autoPlayDelay={20}
					slideDuration={0.5}
					bgColor={"#fffff"}
				/>
				<div className="pointer-events-none absolute -top-[96px] left-0 z-0 h-screen w-screen bg-gradient-to-b from-black via-transparent to-transparent"></div>
				<div className="pointer-events-none absolute top-32 left-[10px] z-0 flex md:left-[80px] lg:left-[100px]">
					<div className="">
						<h2 className="relative z-30 mx-auto w-fit px-2 py-4 font-Prata text-3xl font-bold uppercase leading-normal text-white sm:px-2 sm:py-4 sm:text-4xl md:px-3 md:py-6 md:text-5xl md:leading-normal">
							Pressearbeit vom Feinsten.
						</h2>
					</div>
				</div>
				<div className="absolute bottom-16 left-1/2 z-10 -m-8 flex w-fit animate-bounce">
					<button className="m-auto h-16 w-16 rounded-full hover:cursor-pointer" onClick={scrollOnClick}>
						<svg
							aria-hidden="true"
							focusable="false"
							data-prefix="fas"
							data-icon="chevron-double-down"
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 448 512"
							className="svg-inline--fa fa-chevron-double-down fa-w-14 fa-3x relative text-light-green">
							<path
								fill="currentColor"
								d="M207 477.5L12.7 283.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 477.5c-9.4 9.3-24.6 9.3-34 0zm34-192L435.3 91.1c9.4-9.4 9.4-24.6 0-33.9l-22.7-22.7c-9.4-9.4-24.5-9.4-33.9 0L224 188.5 69.3 34.5c-9.4-9.3-24.5-9.3-33.9 0L12.7 57.2c-9.4 9.4-9.4 24.6 0 33.9L207 285.5c9.4 9.3 24.6 9.3 34 0z"></path>
						</svg>
					</button>
				</div>
			</div>
			<div ref={toScroll} className="mt-10 md:mt-20">
				<Fade triggerOnce={true}>
					<VitaCard />
					<RefBanner />
				</Fade>
			</div>
		</>
	);
};

export default Home;
