import React, { useState } from "react";

import { NavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/*import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';*/
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/*Import Image*/
import Logo from "../images/cropped-rad-dunkelgruen.png";

const Navigation = () => {
	const MenuItems = [
		{ name: "Home", path: "/" },
		{ name: "Leistungen", path: "/Leistungen" },
		{ name: "Referenzen", path: "/Referenzen" },
		{ name: "Vita", path: "/Vita" },
		{ name: "Das Gartenlabor", path: "https://das-gartenlabor.de/", newpage: "_blank", anchor: true },
		{ name: "Kontakt", path: "/Kontakt" },
	];

	const [menuOpen, setMenuOpen] = useState(false);
	const toggle = () => setMenuOpen(!menuOpen);
	const hide = () => setMenuOpen(false);

	const getActiveClass = (path) => {
		return window.location.pathname === path ? "bg-light-green-200" : "";
	};

	return (
		<>
			<div className="">
				{/*
                <div className="flex flex-col">
                    <div className="flex flex-row flex-nowrap items-center">
                        <FontAwesomeIcon icon={faEnvelope} />
                        <a className="ml-1 text-sm" href="mailto:info@blaetterwerk-redaktionsbuero.de">info@blaetterwerk-redaktionsbuero.de</a>
                    </div>
                    <div className="flex flex-row flex-nowrap items-center">
                        <FontAwesomeIcon icon={faPhone} />
                        <a className="ml-1 text-sm" href="tel:01234567">0123456789</a>
                    </div>
            </div> */}
				<nav className="relative z-20 m-auto flex h-24 w-full flex-col flex-nowrap justify-between rounded-none bg-light-green text-white shadow-sm lg:flex-row">
					<div className="my-auto flex flex-row flex-nowrap items-center justify-between py-1 lg:my-5">
						<div className="flex items-center justify-start">
							<Link
								to="/"
								onClick={hide}
								className="ml-[12px] flex flex-row items-center font-semibold hover:text-dark-green-300 md:ml-12">
								<img src={Logo} className="h-12 w-12" alt="Logo" />
								<div className="ml-4 flex flex-col items-center">
									<span className="font-Prata text-3xl">blätterwerk</span>
									<span className="text-md font-Gothic leading-[0.5rem]">redaktionsbüro</span>
								</div>
							</Link>
						</div>
						<div className="lg:hidden">
							<button
								className="block cursor-pointer rounded border border-solid border-transparent bg-transparent px-3 py-1 text-xl leading-none outline-none focus:outline-none"
								type="button"
								onClick={() => setMenuOpen(!menuOpen)}>
								{menuOpen ? (
									<FontAwesomeIcon icon={faTimes} size="lg" />
								) : (
									<FontAwesomeIcon icon={faBars} size="lg" />
								)}
							</button>
						</div>
					</div>
					<div
						className={
							(menuOpen ? "flex" : "hidden") +
							" relative z-30 items-center bg-light-green transition duration-100 lg:mr-10 lg:flex"
						}>
						<div className="absolute top-0 left-0 z-30 flex w-full flex-col flex-nowrap bg-light-green pb-4 lg:static lg:my-2 lg:flex-row lg:pb-0">
							{MenuItems.map((item, id) => {
								return (
									<div
										key={id}
										className={
											"mx-2 my-1 flex flex-row items-center rounded-md px-2 hover:bg-light-green-300 lg:my-0 " +
											getActiveClass(item.path)
										}>
										{item.anchor ? (
											<a
												key={id}
												href={item.path}
												target={item?.newpage}
												onBlur={hide}
												onClick={toggle}
												className="w-full px-2 py-2 text-lg">
												{item.name}
											</a>
										) : (
											<NavLink
												key={id}
												to={item.path}
												target={item?.newpage}
												onBlur={hide}
												onClick={toggle}
												className="w-full px-2 py-2 text-lg">
												{item.name}
											</NavLink>
										)}
									</div>
								);
							})}
						</div>
					</div>
				</nav>
			</div>
		</>
	);
};

export default Navigation;
